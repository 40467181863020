import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Login from './components/Login';
import Profile from './components/Profile';
import Home from './components/Home';
import Invoice from './components/Invoice';
import Clients from './components/Clients';
import AddClient  from './components/AddClient';
import AddInvoice  from './components/AddInvoice';
import EditInvoice  from './components/EditInvoice';
import PrivateRoute from './components/PrivateRoute'; 
import { AuthProvider } from './components/AuthContext';
import EditProfile  from './components/ProfileEdit';
import EditClient  from './components/EditClient';
import ViewInvoice from './components/ViewInvoice';
// 
import Freelancer from './components/Freelancer';
import AddFreelancer from './components/AddFreelancer';
import EditFreelancer from './components/EditFreelancer';
import viewfreelancer from './components/ViewFreelancer';
import invoiceDate from './components/invoiceDate';
import '../src/App.css';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<PrivateRoute component={Profile} />} />
          <Route path="/home" element={<PrivateRoute component={Home} />} />
          <Route path="/invoice" element={<PrivateRoute component={Invoice} />} />
          <Route path="/attorneys" element={<PrivateRoute component={Clients} />} />
          <Route path="/add-attorney"  element={<PrivateRoute component={AddClient} />} />
          <Route path="/edit-attorney/:id"  element={<PrivateRoute component={EditClient} />} />
          <Route path="/add-invoice" element={<PrivateRoute component={AddInvoice} />} />
          <Route path="/edit-invoice/:invoiceId" element={<EditInvoice />} />
          <Route path="/profile-edit" element={<EditProfile />} />
          <Route path="/view-invoice/:id" element={<ViewInvoice />} />
          <Route path="/freelancers" element={<PrivateRoute component={Freelancer} />} />
          <Route path="/add-freelancer"  element={<PrivateRoute component={AddFreelancer} />} />
          <Route path="/edit-freelancer/:id"  element={<PrivateRoute component={EditFreelancer} />} />
          <Route path="/view-freelancer/:id" element={<PrivateRoute component={viewfreelancer} />}  />
          <Route path="/invoice-information/:id" element={<PrivateRoute component={invoiceDate} />}  />
          <Route path="/" element={<Navigate to="/login" />} />

          
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
