import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { invoiceedit } from './proxyurls'; 
import Layout from './layout';

function ViewInvoice() {
    const [invoice, setinvoice] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { id } = useParams(); 

    useEffect(() => {
        const fetchInvoie = async () => {
            try {
                const response = await axios.get(invoiceedit(id)); 
                setinvoice(response.data);
            } catch (error) {
                console.error('Error fetching invoice details:', error);
                setErrorMessage('Error fetching invoice details');
            }
        };

        fetchInvoie();
    }, [id]);

    const handleBack = () => {
        navigate('/invoice');
    };

    if (!invoice && !errorMessage) {
        return <div>Loading...</div>;
    }
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
      
        return `${month}-${day}-${year}`;
      };

    return (
        <Layout>
            <div style={{ padding: '39px' }}>
                <table className="table table-bordered">
                    <thead>
                        <h4 className='mb-4 mt-3'>Invoice Details</h4>
                        <tr>
                        <th>Invoice Create Date(us)</th>
                        <td>{ invoice.invoice_create_date ? formatDate(new Date(invoice.invoice_create_date)): "- -"}</td>
                        </tr>
                        <tr>
                        <th>Invoice Send Date(us)</th>
                        <td>{invoice.invoice_send_date ? formatDate(new Date(invoice.invoice_send_date)): "- -"}</td>
                        </tr>
                        <tr>
                        <th>Invoice Pay Date(us)</th>
                        <td>{ invoice.payment_status_date ? formatDate(new Date(invoice.payment_status_date)) : "- -"}</td>
                        </tr>
                    </thead>
                </table>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ViewInvoice;
