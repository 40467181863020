import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { freelancerdetails } from './proxyurls'; 
import Layout from './layout';

function ViewFreelancer() {
    const [freelancer, setFreelancer] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { id } = useParams(); 

    useEffect(() => {
        const fetchFreelancer = async () => {
            try {
                const response = await axios.get(freelancerdetails(id)); 
                setFreelancer(response.data);
            } catch (error) {
                console.error('Error fetching freelancer details:', error);
                setErrorMessage('Error fetching freelancer details');
            }
        };

        fetchFreelancer();
    }, [id]);

    const handleBack = () => {
        navigate('/freelancers');
    };

    if (!freelancer && !errorMessage) {
        return <div>Loading...</div>;
    }
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
      
        return `${month}-${day}-${year}`;
      };
      const projectStartDate = freelancer?.project_startdate;
      const projectendDate = freelancer?.project_enddate;

    return (
        <Layout>
            <div style={{ padding: '39px' }}>
                <table className="table table-bordered">
                    <thead>
                        <h4 className='mb-4 mt-3'>Freelancer Details</h4>
                        <tr>
                        <th>Name</th>
                        <td>{freelancer?.firstname } {freelancer?.lastname || ''}</td>
                        </tr>
                        <tr>
                        <th>Email</th>
                        <td>{freelancer?.email || ''}</td>
                        </tr>
                        <tr>
                        <th>Phone Number</th>
                        <td>{freelancer?.phone || ''}</td>
                        </tr>
                        <tr>
                        <th>Project Id</th>
                        <td>{freelancer?.project_id || ''}</td>
                        </tr>
                        <tr>
                        <th>Title</th>
                        <td>{freelancer?.title || ''}</td>
                        </tr>
                        <tr>
                        <th>Services Details</th>
                        <td>{freelancer?.services_details || ''}</td>
                        </tr>
                        <tr>
                        <th>Project Name</th>
                        <td>{freelancer?.projectname || ''}</td>
                        </tr>
                        <tr>
                        <th>Indian Rupee</th>
                        <td>{freelancer?.inr || ''}</td>
                        </tr>
                        <tr>
                        <th>Payment Status</th>
                        <td>{freelancer?.payment_status === 1 ? 'Done' : 'Pending'}</td>
                        </tr>
                        <tr>
                        <th>Payment Remarks</th>
                        <td>{freelancer?.payment_remarks || ''}</td>
                        </tr>
                        
                        <tr>
                        <th>Project Status</th>
                        <td>{freelancer?.project_status === 1 ? 'Done' : 'Pending'}</td>
                        </tr>
                        <tr>
                        <th>Project Start Date(us)</th>
                        <td>{formatDate(new Date(projectStartDate))}</td>
                        </tr>
                        <tr>
                        <th>project End Date(us)</th>
                        <td>{formatDate(new Date(projectendDate))}</td>
                        </tr>
                    </thead>
                </table>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ViewFreelancer;
