import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from './layout';
import { invoice } from './proxyurls';
import { useNavigate } from 'react-router-dom';

function Invoice() {
  const [invoices, setInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [invoicesPerPage] = useState(12); 
  const [successMessage, setSuccessMessage] = useState(''); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(invoice);
        setInvoices(response.data);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    fetchInvoices();
  }, []);

  useEffect(() => {
    const successMessage = localStorage.getItem('successMessage');
    if (successMessage) {
      setSuccessMessage(successMessage);
      localStorage.removeItem('successMessage');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    }
  }, []);

  const sortedInvoices = invoices.sort((a, b) => a.status - b.status);

  const indexOfLastInvoice = currentPage * invoicesPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
  const currentInvoices = sortedInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const totalPages = Math.ceil(sortedInvoices.length / invoicesPerPage);

  const handleViewInvoice = (id) => {
    window.open(`/view-invoice/${id}`); 
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${month}-${day}-${year}`;
  };

  return (
    <>
      <Layout>
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          <section className='w-100 p-3'>
            <div style={{ padding: '39px' }}>
              <div className='d-flex my-3'>
                <h4>Manage Invoices</h4>
                <button
                  type="button"
                  className="btn btn-outline-success mt-1"
                  style={{ marginLeft: 'auto', height: '42px' }}
                  onClick={() => navigate('/add-invoice')}
                >
                  Add Invoice
                </button>
              </div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ padding: '13px' }}>Attorney</th>
                    <th style={{ padding: '13px' }}>Project</th>
                    <th style={{ padding: '13px' }}>Email</th>
                    <th style={{ padding: '13px' }}>USD</th>
                    <th style={{ padding: '13px' }}>Payment</th>
                    <th style={{ padding: '13px' }}>Status</th>
                    <th style={{ padding: '13px',width:'10%' }}>Actions</th>
                  </tr>
                </thead>    
                <tbody>
                  {currentInvoices.length > 0 ? ( 
                    currentInvoices.map((invoice) => (
                      <tr className='table_list' key={invoice.id}>
                       <td>{invoice.assigned ? `${invoice.assigned.firstname} ${invoice.assigned.lastname}` : ''}</td>
                        <td>{invoice.title.length > 30 ? invoice.title.slice(0, 30) + '...' : invoice.title}</td>
                        <td>{invoice.assigned_email}</td>
                        <td>${invoice.usd}</td>
                        <td>{invoice.payment_status === 0 ? 'Pending' : 'Done'}</td>
                        <td  style={{fontSize:'25px', paddingLeft:'32px'}}>
                            {invoice.latest_document_url ? (
                                <a style={{marginLeft:'33px'}} href={invoice.latest_document_url} target="_blank" rel="noopener noreferrer">
                                    <i style={{color:'#4ab58e',fontSize:'18px'}} class="fa-solid fa-file-arrow-down"></i>
                                </a>
                            ) : (
                              <i style={{color:'#ffcf00',fontSize:'18px'}} class="fa-solid fa-xmark iconss"></i>
                            )}
                        </td>
                        <td>
                          <div className='btn-group'>
                            <button
                              type="button"
                              className="btn btn-outline-info"
                              onClick={() => handleViewInvoice(invoice.id)}
                              title='View'
                            >
                              <i className="fa fa-eye"></i>
                            </button>
                            {/*  */}
                            <button
                              type="button"
                              className="btn btn-outline-success "
                              onClick={() => navigate(`/invoice-information/${invoice.id}`)}
                              title='Information'
                            >
                              <i class="fa-solid fa-circle-info"></i>
                            </button>
                            {/*  */}
                            <button
                              type="button"
                              className="btn btn-outline-warning "
                              onClick={() => navigate(`/edit-invoice/${invoice.id}`)}
                              title='Edit'
                            >
                              <i className="fa fa-edit"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" style={{ textAlign: 'center' }}>
                        No invoices available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="datatable-pagination d-flex justify-content-end">
                <div className="datatable-pagination-buttons">
                  <button
                    className="btn btn-link datatable-pagination-button"
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="fa fa-chevron-left" />
                  </button>
                  <span>{currentPage} of {totalPages}</span>
                  <button
                    className="btn btn-link datatable-pagination-button"
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="fa fa-chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </section>
      </Layout>
    </>
  );
}

export default Invoice; 
